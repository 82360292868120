<template>
  <ion-page id="characters-page" class="page scrollable bg-transparent">
    <div class="d-flex justify-content-between align-items-center flex-wrap py-3">
      <div class="title">Manage Listings</div>

      <div class="ml-auto">
        <ion-button color="primary" class="new-listing-btn" @click="createListing">New Listing</ion-button>
      </div>
    </div>

    <div v-if="get(allListings, 'length')" class="row w-100 m-0">
      <Grid :xl="3" :lg="4" :md="2" :sm="2" :scrollSm="false">
        <MarketplaceCard
          :openBlank="true"
          class="grid-item"
          v-for="(listing, index) in allListings"
          :listing="listing"
          :key="index"
        />
      </Grid>
    </div>

    <div v-if="nextpageExits && !isLoading" class="d-flex justify-content-center">
      <p class="clickable-item-hov" @click="requestLoadMore">Load More</p>
    </div>

    <div v-if="isLoading" class="d-flex justify-content-center align-items-center">
      <ChLoading size="lg" class="spinner" />
    </div>
    <div v-else-if="!get(allListings, 'length')" class="text-center my-4">No Active Listings.</div>
  </ion-page>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import { toast } from '@/shared/native';
import { getUserMarketplaceListings, getNextPage } from '@/shared/actions/marketplace';
import type { Paging } from '@/shared/types/static-types';
import { updateUserdata } from '@/shared/actions/users';
import { authStore } from '@/shared/pinia-store/auth';
import MarketplaceCard from '@/shared/components/storage/MarketplaceCard.vue';
import Grid from '@/shared/components/storage/Grid.vue';
import store from '@/shared/store';

const { user } = authStore();
const router = useRouter();
const allListings: any = ref([]);
const isLoading = ref(true);
const marketPlacePaging = ref<Paging | null>(null);

const getUserByUsername = (args: any) => store.dispatch('UsersModule/getUserByUsername', args);

const fetchMPListings = async (page = 1) => {
  isLoading.value = true;

  try {
    const { results, ...paging } = await getUserMarketplaceListings(user.value.id, page);

    allListings.value = results;
    marketPlacePaging.value = paging;
  } catch (e) {
    await toast.show('Could not load listings. Please try again.', 'nonative', 'danger');
  }

  isLoading.value = false;
};

const requestLoadMore = async () => {
  isLoading.value = true;

  try {
    const { results, ...paging } = await getNextPage(marketPlacePaging.value!);
    allListings.value = allListings.value.concat(results);
    marketPlacePaging.value = paging;
  } catch (e) {
    await toast.show('Could not load listings. Please try again.', 'nonative', 'danger');
  }

  isLoading.value = false;
};

const nextpageExits = computed(() => {
  return !!marketPlacePaging.value?.next;
});

const createListing = async () => {
  if (!user.value.mp_agreement) {
    try {
      const alert = await alertController.create({
        cssClass: 'marketplace-post-alert',
        header: 'Welcome to marketplace! A few notes:',
        message: `<ul class="text-black">
  <li>Payments will be through PayPal when you cash out (PayPal fees apply). <b>You must have PayPal!</b></li>
  <li>Minimum cash out is $10</li>
  <li>You get the <b>FULL amount you make</b>. We take a small fee from the buyer when they order.</li>
  <li>Do not sell anything that is not yours or is copyrighted. No AI related works or services allowed.</li>
  <li>Read about all the rules and suggestions <b><a rel="nofollow" class="link" href="https://docs.google.com/document/d/15R6A5rNy9p_Ue0YmxN2nVKWd61LZvyR9nTNXXiZmRW4/edit?usp=sharing" target="_blank" class="text-black clickable-item-hov">here</a></b>.</li>
</ul>`,
        buttons: [
          {
            text: 'Start Posting',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'text-primary',
          },
        ],
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();

      if (role === 'ok') {
        const { updateLoggedInUserData } = authStore();
        updateLoggedInUserData({ mp_agreement: true });
        await updateUserdata(user.value.id, { mp_agreement: true });
        router.push({ name: 'create-mp-listing' });
      }
    } catch (err) {
      toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }
  } else {
    router.push({ name: 'create-mp-listing' });
  }
};

onMounted(async () => {
  fetchMPListings();

  if (user.value.id) {
    await getUserByUsername(user.value.username);
  }
});
</script>

<style lang="sass" scoped>
.name
  font-size: 18px
  font-weight: bold
  color: #737373
  cursor: pointer
  .name:hover
    opacity: 0.7

.new-listing-btn
  color: #214163
  font-size: 14px
  font-weight: bold
.title
  font-size: 24px
  font-weight: bold

.one-listing
  img
    height: 130px
    width: 100vh !important
    object-fit: cover

.baseprice
  font-size: 18px
  font-weight: bold

.listing-card
  height: calc(100% - 1.5rem) !important
</style>
